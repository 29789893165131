//
// Drawer
//

// Drawer
/**
 * 2022/06/10 - this class conflicts with CommandBar's styles,
 * which too use a `.drawer` for their Command Editor window.
 * Since we aren't explicitly using this Metronic drawer, this goes.
 *
.drawer {
	display: flex !important;
	overflow: auto;
	z-index: get($drawer, z-index);
	position: fixed;
	top: 0;
	bottom: 0;
	background-color: get($drawer, bg-color);
	transition: transform get($drawer, transition-speed) ease-in-out;

	&.drawer-start {
		left: 0;
		transform: translateX(-100%);
	}

	&.drawer-end {
		right: 0;
		transform: translateX(100%);
	}

	&.drawer-on {
		transform: none;
		box-shadow: get($drawer, box-shadow);
		transition: transform get($drawer, transition-speed) ease-in-out;
	}
}

// Drawer Overlay
.drawer-overlay {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	z-index: get($drawer, z-index) - 1;
	background-color: get($drawer, overlay-bg-color);
	animation: animation-drawer-fade-in get($drawer, overlay-animation-speed) ease-in-out 1;
}
*/

// Initial state
[data-kt-drawer="true"] {
	display: none;
}

// Animation
@keyframes animation-drawer-fade-in {
	from   { opacity: 0; }
	to { opacity: 1; }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(lg) {
	body[data-kt-drawer="on"] {
		overflow: hidden;
	}
}
