/* dm-sans-regular - latin */
/* font files downloaded from http://google-webfonts-helper.herokuapp.com */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-Thin.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 100;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-ThinItalic.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-ExtraLight.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 200;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-ExtraLightItalic.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-Light.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-LightItalic.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-Regular.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-Italic.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-Medium.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-MediumItalic.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-Bold.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-BoldItalic.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-ExtraBold.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-Black.ttf') format('truetype'),
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    src: local(''),
        url('@/assets/fonts/poppins/Poppins-BlackItalic.ttf') format('truetype'),
}
